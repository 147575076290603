import React from "react";
import featureImg from "../../assets/about/feature.jpg";

const Safety = () => {
  return (
    <div className="container-fluid feature bg-light pb-5">
      <div className="container pb-5">
        <div
          className="text-center mx-auto pb-5 wow fadeInUp"
          data-wow-delay="0.2s"
          style={{ maxWidth: "800px" }}
        >
          <h4 className="text-primary">Our Features</h4>
          <h1 className="display-4 mb-4">
            We Secure Your Future with the Best Insurance Plans
          </h1>
          <p className="mb-0">
            At Kalfrenat Insurance, we offer a range of comprehensive insurance
            services designed to secure your future. Whether it's protecting
            your assets, providing financial advice, or offering flexible plans,
            we have you covered.
          </p>
        </div>

        <div className="row g-4">
          {/* Feature Item 1 */}
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="feature-item p-4 pt-0">
              <div className="feature-icon p-4 mb-4">
                <i className="far fa-handshake fa-3x"></i>
              </div>
              <h4 className="mb-4">Trusted Company</h4>
              <p className="mb-4">
                With years of industry experience, Kalfrenat Insurance is a
                company you can rely on for transparent and secure insurance
                services.
              </p>
              <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                Learn More
              </a>
            </div>
          </div>

          {/* Feature Item 2 */}
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div className="feature-item p-4 pt-0">
              <div className="feature-icon p-4 mb-4">
                <i className="fa fa-dollar-sign fa-3x"></i>
              </div>
              <h4 className="mb-4">Flexible Refund Options</h4>
              <p className="mb-4">
                We offer flexible policies that allow for refunds and
                adjustments, ensuring that your investment is always protected.
              </p>
              <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                Learn More
              </a>
            </div>
          </div>

          {/* Feature Item 3 */}
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div className="feature-item p-4 pt-0">
              <div className="feature-icon p-4 mb-4">
                <i className="fa fa-bullseye fa-3x"></i>
              </div>
              <h4 className="mb-4">Customizable Plans</h4>
              <p className="mb-4">
                Our insurance plans are tailored to meet your specific needs,
                ensuring maximum coverage and flexibility for your assets and
                investments.
              </p>
              <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                Learn More
              </a>
            </div>
          </div>

          {/* Feature Item 4 */}
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div className="feature-item p-4 pt-0">
              <div className="feature-icon p-4 mb-4">
                <i className="fa fa-headphones fa-3x"></i>
              </div>
              <h4 className="mb-4">24/7 Customer Support</h4>
              <p className="mb-4">
                Our dedicated support team is available around the clock to
                assist you with any questions or claims, ensuring fast and
                reliable service.
              </p>
              <a className="btn btn-primary rounded-pill py-2 px-4" href="#">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Safety;
