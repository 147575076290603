import React from "react";
import carousel_image from "../../assets/about/carousel-2.png"; // Correct image import

const Faq = () => {
  return (
    <div className="container-fluid faq-section bg-light pb-5">
      <div className="container pb-5">
        <div className="row g-5 align-items-center">
          {/* Left Column: FAQ Section */}
          <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
            <div className="h-100">
              <div className="mb-5">
                <h4 className="text-primary">Important Questions</h4>
                <h1 className="display-4 mb-0">
                  Frequently Asked Questions About Our Insurance Services
                </h1>
              </div>

              {/* Accordion for FAQs */}
              <div className="accordion" id="accordionExample">
                {/* Accordion Item 1 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button border-0"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Q: What types of insurance policies do you offer?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body rounded">
                      A: At Kalfrenat Insurance, we offer a wide range of
                      policies, including Precious Metals and Minerals
                      Insurance, Financial Advisory Services, Investment
                      Assistance, and Dispute Mediation. Our plans are flexible
                      and customizable to suit your specific needs.
                    </div>
                  </div>
                </div>

                {/* Accordion Item 2 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Q: How can I file a claim?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      A: You can easily file a claim through our online portal
                      or by contacting our 24/7 customer support. We aim to
                      process claims swiftly, ensuring that you get assistance
                      without delays.
                    </div>
                  </div>
                </div>

                {/* Accordion Item 3 */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Q: Can I customize my insurance plan?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      A: Yes, our insurance plans are highly flexible, allowing
                      you to choose the coverage that best fits your unique
                      situation. Whether it's for personal or corporate needs,
                      we have options that you can tailor to your requirements.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Column: Image */}
          <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.4s">
            <img
              src={carousel_image} // Use imported image here
              className="img-fluid w-100"
              alt="FAQ Section Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
