import React from "react";
import "./Features.scss"; // Ensure this is correctly linked
import icon06 from "../../assets/icon/icon-06-primary.png";
import icon03 from "../../assets/icon/icon-03-primary.png";
import icon04 from "../../assets/icon/icon-04-primary.png";
import icon07 from "../../assets/icon/icon-07-primary.png";
import featureImg from "../../assets/feature.jpg"; // Adjust path if necessary

const Features = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="display-6 mb-5">Why Choose Kalfrenat Insurance?</h1>
            <p className="mb-4">
              At Kalfrenat Insurance, we prioritize your financial security with
              reliable insurance plans tailored to meet the diverse needs of
              both individuals and businesses. Here’s why clients choose us:
            </p>
            <div className="row g-3">
              <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                <div className="bg-light rounded h-100 p-3">
                  <div className="bg-white d-flex flex-column justify-content-center text-center rounded h-100 py-4 px-3">
                    <img
                      className="align-self-center mb-3"
                      src={icon06}
                      alt="Comprehensive Coverage"
                    />
                    <h5 className="mb-0">Comprehensive Coverage</h5>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                <div className="bg-light rounded h-100 p-3">
                  <div className="bg-white d-flex flex-column justify-content-center text-center rounded py-4 px-3">
                    <img
                      className="align-self-center mb-3"
                      src={icon03}
                      alt="Quick Claims Processing"
                    />
                    <h5 className="mb-0">Quick Claims Processing</h5>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                <div className="bg-light rounded h-100 p-3">
                  <div className="bg-white d-flex flex-column justify-content-center text-center rounded py-4 px-3">
                    <img
                      className="align-self-center mb-3"
                      src={icon04}
                      alt="Flexible Policy Options"
                    />
                    <h5 className="mb-0">Flexible Policy Options</h5>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                <div className="bg-light rounded h-100 p-3">
                  <div className="bg-white d-flex flex-column justify-content-center text-center rounded h-100 py-4 px-3">
                    <img
                      className="align-self-center mb-3"
                      src={icon07}
                      alt="Cost-Effective Solutions"
                    />
                    <h5 className="mb-0">Cost-Effective Solutions</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div
              className="position-relative rounded overflow-hidden h-100"
              style={{ minHeight: "400px" }}
            >
              <img
                className="position-absolute w-100 h-100"
                src={featureImg}
                alt="Insurance Services"
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
