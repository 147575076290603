import React from "react";
import shapeFour from "../../assets/service/about.jpg";

import icon06 from "../../assets/icon/icon-06-primary.png";
import icon03 from "../../assets/icon/icon-03-primary.png";
import icon04 from "../../assets/icon/icon-04-primary.png";

import "./style.css"; // Ensure you have the necessary styles

const Services = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden rounded ps-5 pt-5 h-100"
              style={{ minHeight: "400px" }}
            >
              <img
                className="position-absolute w-100 h-100"
                src={shapeFour} // Use the imported image here
                alt="About Us"
                style={{ objectFit: "cover" }}
              />
              <div
                className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3"
                style={{ width: "200px", height: "200px" }}
              >
                <div className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3">
                  <h1 className="text-white mb-0"></h1>
                  <h2 className="text-white"></h2>
                  <h5 className="text-white mb-0">Committed to Industry Success</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="h-100">
              <h1 className="display-6 mb-5">
                Securing Your Investments with Confidence
              </h1>
              <p className="fs-5 text-primary mb-4">
                At Kalfrenat Insurance, we offer specialized services designed
                to protect your valuable assets and investments.
              </p>
              <div className="row g-4 mb-4">
                <div className="col-sm-6">
                  <div className="d-flex align-items-center">
                    <img
                      className="flex-shrink-0 me-3"
                      src={icon04} // Use the imported icon here
                      alt="Flexible Insurance Plans"
                    />
                    <h5 className="mb-0">Comprehensive Insurance Plans</h5>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex align-items-center">
                    <img
                      className="flex-shrink-0 me-3"
                      src={icon03} // Use the imported icon here
                      alt="Money Back Guarantee"
                    />
                    <h5 className="mb-0">Risk-Free Investment Solutions</h5>
                  </div>
                </div>
              </div>
              <p className="mb-4">
                Whether you're looking for protection in Precious Metals and
                Minerals Insurance or need Financial Advisory and Investment
                Assistance, we are here to guide you every step of the way.
              </p>
              <div className="border-top mt-4 pt-4">
                <div className="d-flex align-items-center">
                  <img
                    className="flex-shrink-0 rounded-circle me-3"
                    src="img/profile.jpg"
                  />
                  <h5 className="mb-0">Call Us: +254 786 569 930</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
