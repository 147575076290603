import React from "react";
import blog1 from "../../assets/service/blog-1.png";
import blog2 from "../../assets/service/blog-2.png";
import blog3 from "../../assets/service/blog-3.png";
import blog4 from "../../assets/service/blog-4.png";

const Symptoms = () => {
  return (
    <div className="container-fluid service py-5">
      <div className="container py-5">
        <div
          className="text-center mx-auto pb-5 wow fadeInUp"
          data-wow-delay="0.2s"
          style={{ maxWidth: "800px" }}
        >
          <h4 className="text-primary">Our Services</h4>
          <h1 className="display-4 mb-4">
            We Provide the Best Insurance Services
          </h1>
          <p className="mb-0">
            At Kalfrenat Insurance, we are dedicated to providing comprehensive
            solutions tailored to your unique needs. Our services offer peace of
            mind and financial security across various sectors.
          </p>
        </div>

        <div className="row g-4 justify-content-center">
          {/* Service Item 1 */}
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="service-item">
              <div className="service-img">
                <img
                  src={blog1}
                  className="img-fluid rounded-top w-100"
                  alt="Precious Metals and Minerals Insurance"
                  style={{ width: "100%", height: "250px", objectFit: "cover" }} // Ensuring image dimensions
                />
              </div>
              <div className="service-content p-4">
                <div className="service-content-inner">
                  <a href="#" className="d-inline-block h4 mb-4">
                    Precious Metals and Minerals Insurance
                  </a>
                  <p className="mb-4">
                    We provide tailored insurance solutions to protect gold,
                    silver, platinum, and other valuable minerals, ensuring that
                    our clients' investments are secure.
                  </p>
                  <a
                    className="btn btn-primary rounded-pill py-2 px-4"
                    href="#"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Service Item 2 */}
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <div className="service-item">
              <div className="service-img">
                <img
                  src={blog2}
                  className="img-fluid rounded-top w-100"
                  alt="Financial Advisory"
                  style={{ width: "100%", height: "250px", objectFit: "cover" }} // Ensuring image dimensions
                />
              </div>
              <div className="service-content p-4">
                <div className="service-content-inner">
                  <a href="#" className="d-inline-block h4 mb-4">
                    Financial Advisory
                  </a>
                  <p className="mb-4">
                    Our team of experts offers strategic financial advice,
                    helping clients make informed decisions to maximize their
                    investment potential.
                  </p>
                  <a
                    className="btn btn-primary rounded-pill py-2 px-4"
                    href="#"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Service Item 3 */}
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            <div className="service-item">
              <div className="service-img">
                <img
                  src={blog3}
                  className="img-fluid rounded-top w-100"
                  alt="Investment Assistance"
                  style={{ width: "100%", height: "250px", objectFit: "cover" }} // Ensuring image dimensions
                />
              </div>
              <div className="service-content p-4">
                <div className="service-content-inner">
                  <a href="#" className="d-inline-block h4 mb-4">
                    Investment Assistance
                  </a>
                  <p className="mb-4">
                    We assist clients in identifying and pursuing lucrative
                    investment opportunities, guiding them throughout the
                    investment process.
                  </p>
                  <a
                    className="btn btn-primary rounded-pill py-2 px-4"
                    href="#"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Service Item 4 */}
          <div
            className="col-md-6 col-lg-6 col-xl-3 wow fadeInUp"
            data-wow-delay="0.8s"
          >
            <div className="service-item">
              <div className="service-img">
                <img
                  src={blog4}
                  className="img-fluid rounded-top w-100"
                  alt="Dispute Mediation"
                  style={{ width: "100%", height: "250px", objectFit: "cover" }} // Ensuring image dimensions
                />
              </div>
              <div className="service-content p-4">
                <div className="service-content-inner">
                  <a href="#" className="d-inline-block h4 mb-4">
                    Dispute Mediation
                  </a>
                  <p className="mb-4">
                    At Kalfrenat Insurance, we act as intermediaries in case of
                    any disputes, ensuring our clients receive fair treatment
                    and resolution in a timely manner.
                  </p>
                  <a
                    className="btn btn-primary rounded-pill py-2 px-4"
                    href="#"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* More Services Button */}
          <div
            className="col-12 text-center wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <a className="btn btn-primary rounded-pill py-3 px-5" href="#">
              More Services
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Symptoms;
