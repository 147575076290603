import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import shapeOne from "../../assets/carousel/carousel-1.jpg"; // Update path if necessary
import shapeTwo from "../../assets/carousel/carousel-2.jpg"; // Update path if necessary

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const Banner = () => {
  return (
    <div className="container-fluid p-0 pb-5">
      <Slider
        {...carouselSettings}
        className="header-carousel position-relative mb-5"
      >
        {/* First Slide */}
        <div className="owl-carousel-item position-relative">
          <img className="img-fluid" src={shapeOne} alt="Insurance Slide 1" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(6, 3, 21, .5)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Comprehensive Insurance Solutions
                  </h5>
                  <h1 className="display-3 text-white animated slideInDown mb-4">
                    Secure Your Future with{" "}
                    <span className="text-primary">Tailored Insurance</span>
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                    Protecting what matters most with our customizable insurance
                    packages – because your security is our priority.
                  </p>
                  <a
                    href="#"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Learn More
                  </a>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Slide */}
        <div className="owl-carousel-item position-relative">
          <img className="img-fluid" src={shapeTwo} alt="Insurance Slide 2" />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(6, 3, 21, .5)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-10 col-lg-8">
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Financial Security & Protection
                  </h5>
                  <h1 className="display-3 text-white animated slideInDown mb-4">
                    Trust Kalfrenat for{" "}
                    <span className="text-primary">Reliable Coverage</span>
                  </h1>
                  <p className="fs-5 fw-medium text-white mb-4 pb-2">
                    Offering a range of policies to protect your assets, family,
                    and business with the trust and reliability you deserve.
                  </p>
                  <a
                    href="#"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Banner;
