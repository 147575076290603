import React, { useState } from "react";
import Modal from "react-modal";
import aboutImage from "../../assets/about/about.jpg"; // Update the path as needed

const Priority = () => {
  return (
    <div className="container-fluid bg-light about py-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
            <div className="about-item-content bg-white rounded p-5 h-100">
              <h4 className="text-primary">
                About Kalfrenat Insurance Company Limited{" "}
              </h4>
              <h1 className="display-4 mb-4">
                
              </h1>
              <p>
                Kalfrenat Insurance Company Limited is a premier insurance
                provider specializing in the protection of precious metals and
                other minerals. We are dedicated to safeguarding our clients'
                valuable assets while providing expert financial advice to
                ensure their investments flourish.
              </p>
              <p>
                Our comprehensive services are designed to meet the diverse
                needs of our clients, helping them navigate the complexities of
                insurance and investment with ease and confidence.
              </p>
              <p className="text-dark">
                <i className="fa fa-check text-primary me-3"></i>We can save
                your money.
              </p>
              <p className="text-dark">
                <i className="fa fa-check text-primary me-3"></i>Production or
                trading of good
              </p>
              <p className="text-dark mb-4">
                <i className="fa fa-check text-primary me-3"></i>Our life
                insurance is flexible
              </p>
              <a className="btn btn-primary rounded-pill py-3 px-5" href="#">
                More Information
              </a>
            </div>
          </div>
          <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
            <div className="bg-white rounded p-5 h-100">
              <div className="row g-4 justify-content-center">
                <div className="col-12">
                  <div className="rounded bg-light">
                    <img
                      src={aboutImage}
                      className="img-fluid rounded w-100"
                      alt="About Our Company"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                      <span
                        className="text-primary fs-2 fw-bold"
                        data-toggle="counter-up"
                      >
                        129
                      </span>
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-dark">Insurance Policies</h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                      <span
                        className="text-primary fs-2 fw-bold"
                        data-toggle="counter-up"
                      >
                        99
                      </span>
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-dark">Awards WON</h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                      <span
                        className="text-primary fs-2 fw-bold"
                        data-toggle="counter-up"
                      >
                        556
                      </span>
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-dark">Skilled Agents</h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                      <span
                        className="text-primary fs-2 fw-bold"
                        data-toggle="counter-up"
                      >
                        967
                      </span>
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-dark">Team Members</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Priority;
