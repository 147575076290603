import imgOne from "../../assets/blogs/1.png";
import imgTwo from "../../assets/blogs/2.png";
import imgThree from "../../assets/blogs/3.png";

const BlogData = [
  {
    img: imgOne,
    url: "importance-of-insuring-precious-metals",
    title: "Importance of Insuring Precious Metals",
    description:
      "Learn why insuring your investments in precious metals like gold and silver is critical for protecting your assets.",
    category: "INSURANCE",
  },
  {
    img: imgTwo,
    url: "financial-advisory-tips",
    title: "Top Financial Advisory Tips for Investors",
    description:
      "Get expert advice on how to make informed financial decisions that maximize your investment returns.",
    category: "FINANCIAL ADVISORY",
  },
  {
    img: imgThree,
    url: "dispute-mediation-services",
    title: "How Dispute Mediation Can Help Resolve Conflicts",
    description:
      "Find out how Kalfrenat Insurance’s dispute mediation services can help resolve conflicts quickly and fairly.",
    category: "MEDIATION",
  },
];

export default BlogData;
