import React from "react";
import "./Emergency.scss";

const Emergency = () => {
  return (
    <div className="container-fluid overflow-hidden my-5 px-lg-0">
      <div className="container facts px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 facts-text wow fadeIn" data-wow-delay="0.1s">
            <div className="h-100 px-4 ps-lg-0">
              <h1 className="text mb-4">
                Comprehensive Insurance Solutions
              </h1>
              <p className="text-li">
                We provide a wide range of insurance services tailored for both
                individuals and organizations. From precious metals insurance to
                financial advisory, Kalfrenat Insurance is dedicated to securing
                your assets and giving you peace of mind.
              </p>
              <a
                href="/"
                className="align-self-start btn btn-secondary py-3 px-5"
              >
                Explore Our Services
              </a>
            </div>
          </div>
          <div
            className="col-lg-6 facts-counter wow fadeIn"
            data-wow-delay="0.5s"
          >
            <div className="h-100 px-4 pe-lg-0">
              <div className="row g-5">
                <div className="col-sm-6">
                  <h1 className="display-5" data-toggle="counter-up">
                    1000+
                  </h1>
                  <p className="fs-5 text-primary">Satisfied Clients</p>
                </div>
                <div className="col-sm-6">
                  <h1 className="display-5" data-toggle="counter-up">
                    500+
                  </h1>
                  <p className="fs-5 text-primary">Successful Policies</p>
                </div>
                <div className="col-sm-6">
                  <h1 className="display-5" data-toggle="counter-up">
                    50+
                  </h1>
                  <p className="fs-5 text-primary">Awards & Recognitions</p>
                </div>
                <div className="col-sm-6">
                  <h1 className="display-5" data-toggle="counter-up">
                    200+
                  </h1>
                  <p className="fs-5 text-primary">Insurance Experts</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Emergency;
